<template>
    <mercur-card class="fill full-height-layout">
        <grid-header :quickSearch.sync="filters.search">
            HS Codes Configuration
            <template slot="actions">
                <mercur-button
                    @click="openAddHsCodesConfiguration()"
                    class="btn btn-raised btn-yellow text-uppercase"
                    v-if="isAllowedTo('addHsCodesConfiguration')">
                    <i class="fas fa-plus"></i>
                    Add HS Code configuration
                </mercur-button>
            </template>
        </grid-header>

        <data-table
            class="fill full-height-layout border"
            :options="options"
            :url="url"
            ref="grid"
            :quickSearch="filters.search"
            v-if="isAllowedTo('getAllHsCodesConfiguration')"
        ></data-table>
        <hs-codes-configuration-popup
            :dialog-active.sync="hsCodesConfigurationPopupActive"
        ></hs-codes-configuration-popup>

        <mercur-dialog :is-open.sync="deleteConfirmationDialogActive" width="600px">
            <div slot="header"><h3>Are you sure you want to remove Hs codes configuration?</h3></div>
            <div slot="default">It is not possible to recover the Hs codes configuration after deleting.</div>
            <div slot="footer">
                <mercur-button class="btn btn-raised text-uppercase" @click="deleteConfirmationDialogActive = false">Cancel</mercur-button>
                <mercur-button class="btn btn-raised btn-red text-uppercase" @click="deleteHsCodes()">Delete</mercur-button>
            </div>
        </mercur-dialog>
    </mercur-card>
</template>

<script>
import DataTable from '@/components/DataTable'
import CONFIG from '@root/config'
import GridHeader from '@/components/GridHeader'
import HsCodesConfigurationPopup from '../../components/hs_codes/HsCodesConfigurationPopup'

export default {
    name: 'HsCodesOverview',
    components: { HsCodesConfigurationPopup, DataTable, GridHeader },
    data () {
        return {
            hsCodesConfigurationPopupActive: false,
            hsCodesConfigurationDetails: {},
            deleteConfirmationDialogActive: false,
            options: {
                columns: {
                    'Product Config Template Name': {
                        field: 'productConfigurationTemplateName',
                        sortable: true,
                    },
                    'Harmonization Code': {
                        field: 'harmonizationCode',
                    },
                },
                actions: [
                    {
                        icon: 'fas fa-edit',
                        text: 'Edit',
                        to: ({ data }) => {
                            return {
                                name: 'HsCodesProductTemplateEdit',
                                params: {
                                    productConfigurationTemplateId: data.productConfigurationTemplateId,
                                    templateConfigurationType: 'EDIT',
                                },
                            }
                        },
                    },
                    {
                        icon: 'fas fa-trash',
                        text: 'Delete',
                        onClick: ({ data }) => this.openDeleteConfirmDialog(data),
                    },
                ],

                quickSearchColumns: ['productConfigurationTemplateName'],
            },
            url: CONFIG.API.ROUTES.HS_CODES.OVERVIEW,
            filters: {
                search: '',
            },
        }
    },
    methods: {
        openAddHsCodesConfiguration () {
            this.hsCodesConfigurationPopupActive = true
        },
        openDeleteConfirmDialog (data) {
            this.hsCodesConfigurationDetails = data
            this.deleteConfirmationDialogActive = true
        },
        deleteHsCodes () {
            if (!this.isAllowedTo('removeHsCodesConfiguration')) {
                return
            }

            const url = CONFIG.API.ROUTES.HS_CODES.REMOVE
                .replace('{productHsCodeId}', this.hsCodesConfigurationDetails.productHsCodeId)

            this.addJob(url)

            this.$api.post(url).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Hs Codes configuration deleted.`,
                })
                this.$refs.grid.refreshGrid()
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Deleting Hs codes configuration failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.finishJob(url)
            })
        },
    },
}
</script>
