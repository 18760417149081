<template>
    <mercur-dialog :is-open.sync="localDialogActive" width="700px">
        <div slot="header"><h3>Available Product Templates</h3></div>
        <div style="min-height: 70vh;" slot="default">
        <grid-header :quickSearch.sync="filters.search"></grid-header>
            <data-table
                class="fill full-height-layout"
                :options="options"
                :url="url"
                ref="grid"
                :quickSearch="filters.search"
                @update:selectedRows="setActiveTemplate"
                v-if="isAllowedTo('getAvailableProductTemplates')"
            ></data-table>
        </div>
        <div slot="footer">
            <div class="row mt-4">
                <div class="text-left">
                    <mercur-button class="btn btn-raised text-uppercase" @click="localDialogActive = false">Close</mercur-button>
                </div>
                <div class="text-right">
                    <mercur-button class="btn btn-yellow text-uppercase" @click="saveExtraDayConfiguration">Save</mercur-button>
                </div>
            </div>
        </div>
        <mercur-progress-bar indeterminate v-if="loading" />
    </mercur-dialog>
</template>

<script>
import DataTable from '@/components/DataTable'
import GridHeader from '@/components/GridHeader'
import CONFIG from '@root/config'

export default {
    name: 'HsCodesConfigurationPopup',
    components: {
        DataTable,
        GridHeader,
    },
    props: ['dialogActive'],
    data () {
        return {
            loading: false,
            url: CONFIG.API.ROUTES.HS_CODES.GET_AVAILABLE_PRODUCT_TEMPLATES,
            productTemplate: '',
            options: {
                paginationPageSize: 8,
                rowSelection: 'single',
                columns: {
                    'Attribute': {
                        field: 'productConfigurationTemplateName',
                        sortable: false,
                        checkboxSelection: true,
                    },
                },
                quickSearchColumns: ['productConfigurationTemplateName'],
            },
            filters: {
                search: '',
            },
        }
    },
    computed: {
        localDialogActive: {
            get () {
                return this.dialogActive
            },
            set (value) {
                this.$emit('update:dialogActive', value)
            },
        },
    },
    methods: {
        setActiveTemplate (template) {
            this.productTemplate = template[0]
        },
        saveExtraDayConfiguration () {
            this.$router.push({
                name: 'HsCodesProductTemplateEdit',
                params: {
                    productConfigurationTemplateId: this.productTemplate.productConfigurationTemplateId,
                    productConfigurationTemplateName: this.productTemplate.productConfigurationTemplateName,
                    templateConfigurationType: 'ADD',
                },
            })
        },
    },
}
</script>
